// src/context/EventContext.js
import React, { createContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchEventById } from '../services/EventService';

export const EventContext = createContext();

export const EventProvider = ({ children }) => {
  const { id } = useParams();
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadEvent = async () => {
      setLoading(true);
      const data = await fetchEventById(id);
      if (data.success) {
        setEvent(data.event);
      } else {
        setEvent(null);
      }
      setLoading(false);
    };
    loadEvent();
  }, [id]);

  return (
    <EventContext.Provider value={{ event, loading }}>
      {children}
    </EventContext.Provider>
  );
};
