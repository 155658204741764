// src/context/EventsContext.js
import React, { createContext, useState, useEffect } from 'react';
import { fetchActiveEvents } from '../services/EventsService';

export const EventsContext = createContext();

export const EventsProvider = ({ children }) => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const getEvents = async () => {
      const eventsData = await fetchActiveEvents();
      setEvents(eventsData);
    };
    getEvents();
  }, []);

  return (
    <EventsContext.Provider value={{ events }}>
      {children}
    </EventsContext.Provider>
  );
};
