// src/pages/ContactPage.js
import React from 'react';
import ContactInfo from '../components/ContactInfo';
import ContactForm from '../components/forms/ContactForm';
import '../assets/css/global.css';

const ContactPage = () => {
  return (
    <div>
      <section id="contact" className="pt-4 pb-4 bg_grey">
      <br/><br/><br/><br/>
        <div className="container-xl">
          {/* Contact Info Boxes */}
          <ContactInfo />

          <div className="row contact_2 mt-4">
            <div className="col-md-3">
              {/* Left Column Content */}
              <div className="contact_2r">
                <h5 className="mb-3">United States OFFICE</h5>
                <p>
                  <i className="fa fa-car col_red me-1"></i> 141  Sv, CAL 1,USA
                </p>
                <p>
                  <i className="fa fa-phone col_red me-1"></i> +123 123 456
                </p>
                <p>
                  <i className="fa fa-envelope col_red me-1"></i>{' '}
                  <a href="mailto:info@gmail.com">info@gmail.com</a>
                </p>
                <h5 className="mb-3 mt-4">BUSINESS HOURS</h5>
                <p>Hotline is available for 24 hours a day!..</p>
                <p>
                  Monday – Friday : <span className="fw-bold text-white">9am to 7pm</span>
                </p>
                <p>
                  Saturday : <span className="fw-bold text-white">11am to 3pm</span>
                </p>
                <p>
                  Sunday : <span className="fw-bold text-white">Closed</span>
                </p>
              </div>
            </div>
            <div className="col-md-9">
              {/* Contact Form */}
              <ContactForm />
            </div>
          </div>

          <div className="row contact_3 mt-4">
            <div className="col-md-12">
              {/* Google Maps Embed */}
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18..."
                height="450"
                style={{ border: 0, width: '100%' }}
                allowFullScreen=""
                loading="lazy"
                title="Google Maps"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactPage;
