// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Pages and Components
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import EventPage from './pages/EventPage';
import CreateEventPage from './pages/CreateEventPage';
import ProfilePage from './pages/ProfilePage';
import MomentsPage from './pages/MomentsPage'; // Import MomentsPage
import LoginForm from './components/forms/LoginForm';
import SignupForm from './components/forms/SignupForm';
import ForgotPasswordForm from './components/forms/ForgotPasswordForm';
import VerifyCodeForm from './components/forms/VerifyCodeForm';
import ResetPasswordForm from './components/forms/ResetPasswordForm';
import ContactPage from './pages/ContactPage';
import Header from './components/Header';
import Footer from './components/Footer';
import MyEventsPage from './pages/MyEventsPage'; // Import MyEventsPage

// Context Providers
import { AuthProvider } from './context/AuthContext';
import { EventsProvider } from './context/EventsContext';
import { EventProvider } from './context/EventContext';

// Import CSS files
import 'bootstrap/dist/css/bootstrap.min.css';
// **Remove** Bootstrap JS import
// import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Remove or comment out
import 'font-awesome/css/font-awesome.min.css';
import './assets/css/global.css';
import './assets/css/index.css';

const App = () => {
  return (
    <AuthProvider>
      <EventsProvider>
        <Router>
          <Header />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route
              path="/event/:id"
              element={
                <EventProvider>
                  <EventPage />
                </EventProvider>
              }
            />
            <Route path="/create-event" element={<CreateEventPage />} />
            <Route path="/my-events" element={<MyEventsPage />} /> {/* New My Events Route */}
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/moments" element={<MomentsPage />} /> {/* New Moments Route */}
            <Route path="/login" element={<LoginForm />} />
            <Route path="/signup" element={<SignupForm />} />
            <Route path="/forgot-password" element={<ForgotPasswordForm />} />
            <Route path="/verify-code" element={<VerifyCodeForm />} />
            <Route path="/reset-password" element={<ResetPasswordForm />} />
            {/* Add other routes here */}
            <Route path="/contact" element={<ContactPage />} />
          </Routes>
          <Footer />
        </Router>
      </EventsProvider>
    </AuthProvider>
  );
};

export default App;
