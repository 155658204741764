// src/components/TopEvents.js
import React, { useContext } from 'react';
import { EventsContext } from '../context/EventsContext';
import { Link } from 'react-router-dom';
import API from '../config'; // Import the configuration

const TopEvents = () => {
  const { events } = useContext(EventsContext);

  // Assuming you have a way to determine the "top" events (e.g., based on views or ratings)
  // For demonstration, we'll take the first 10 events
  const topEvents = events.slice(0, 10);

  // Separate the first event (main event) from the rest
  const mainEvent = topEvents[0];
  const sideEvents = topEvents.slice(1);

  return (
    <section id="play">
      <div className="play_m clearfix">
        <div className="container">
          <div className="row trend_1">
            <div className="col-md-12">
              <div className="trend_1l">
                <h4 className="mb-0 text-white">
                  <i className="fa fa-calendar align-middle col_red me-1"></i>
                  Top <span className="col_red">10 Events</span>
                </h4>
              </div>
            </div>
          </div>
          <div className="play1 row mt-4 bg_grey pt-3 pb-3">
            {/* Main Event */}
            {mainEvent && (
              <div className="col-md-9">
                <div className="play1l">
                  <div className="grid clearfix">
                    <figure className="effect-jazz mb-0">
                      <Link to={`/event/${mainEvent.id}`}>
                        <img
                          src={`${API.UPLOADS}/${mainEvent.event_graphic}`}
                          className="w-100"
                          height="450"
                          alt={mainEvent.event_title}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = '/path_to_default_image.jpg'; // Replace with your default image path
                          }}
                        />
                      </Link>
                    </figure>
                  </div>
                </div>
              </div>
            )}

            {/* Side Events */}
            <div className="col-md-3 ps-0">
              <div className="play1r">
                {sideEvents.map((event, index) => (
                  <div key={event.id} className={`play1ri ${index > 0 ? 'mt-3' : ''}`}>
                    <div className="grid clearfix">
                      <figure className="effect-jazz mb-0">
                        <Link to={`/event/${event.id}`}>
                          <img
                            src={`${API.UPLOADS}/${event.event_graphic}`}
                            className="w-100"
                            alt={event.event_title}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = '/path_to_default_image.jpg'; // Replace with your default image path
                            }}
                          />
                        </Link>
                      </figure>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TopEvents;
