// src/pages/MomentsPage.js
import React, { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import MomentCard from '../components/MomentCard';

// Import images for dummy moments
import momentImage1 from '../assets/img/11.jpg';
import momentImage2 from '../assets/img/12.jpg';
import momentImage3 from '../assets/img/13.jpg';
import momentImage4 from '../assets/img/14.jpg';
import momentImage5 from '../assets/img/15.jpg';
import momentImage6 from '../assets/img/16.jpg';

const MomentsPage = () => {
  const { user } = useContext(AuthContext);

  // Dummy moments data
  const moments = [
    { id: 1, title: 'Moment 1', img: momentImage1 },
    { id: 2, title: 'Moment 2', img: momentImage2 },
    { id: 3, title: 'Moment 3', img: momentImage3 },
    { id: 4, title: 'Moment 4', img: momentImage4 },
    { id: 5, title: 'Moment 5', img: momentImage5 },
    { id: 6, title: 'Moment 6', img: momentImage6 },
  ];

  if (!user) {
    return <div>Please log in to view your moments.</div>;
  }

  return (
    <div className="container">
        <br/><br/><br/><br/>
      <h2 className="text-center mb-5 text-white">Your Moments</h2>
      <div className="row" id="momentsContainer">
        {moments.map((moment) => (
          <MomentCard key={moment.id} moment={moment} />
        ))}
      </div>
    </div>
  );
};

export default MomentsPage;
