// src/components/Header.js
import React, { useContext, useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import logo from '../assets/img/logo.png';
import defaultProfile from '../assets/img/default-profile.jpg';
import SearchModal from './modals/SearchModal'; // Import SearchModal

const Header = () => {
  const { user, logout } = useContext(AuthContext);
  const [showSearchModal, setShowSearchModal] = useState(false);

  useEffect(() => {
    // Sticky navbar logic
    window.onscroll = function () {
      myFunction();
    };

    const navbar_sticky = document.getElementById('navbar_sticky');
    if (!navbar_sticky) return;
    const sticky = navbar_sticky.offsetTop;
    const navbar_height = navbar_sticky.offsetHeight;

    function myFunction() {
      if (window.pageYOffset >= sticky + navbar_height) {
        navbar_sticky.classList.add('sticky');
        document.body.style.paddingTop = navbar_height + 'px';
      } else {
        navbar_sticky.classList.remove('sticky');
        document.body.style.paddingTop = '0';
      }
    }

    // Cleanup function
    return () => {
      window.onscroll = null;
    };
  }, []);

  const handleSearchClose = () => {
    setShowSearchModal(false);
  };

  return (
    <>
      <section id="header" style={{ position: 'fixed', top: 0, width: '100%', zIndex: 3 }}>
        <nav className="navbar navbar-expand-md navbar-light" id="navbar_sticky">
          <div className="container">
            <Link className="navbar-brand fw-bold" to="/">
              <img src={logo} width="50" height="70" alt="SPONO Logo" /> S P O N O
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav mb-0 ms-auto" id="nav-items">
                <li className="nav-item">
                  <NavLink
                    to="/"
                    className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
                  >
                    Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/about"
                    className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
                  >
                    About Us
                  </NavLink>
                </li>
                {user ? (
                  <>
                    {/* Show "Moments" only for event_attendee users */}
                    {user.user_type === 'event_attendee' && (
                      <li className="nav-item">
                        <NavLink
                          to="/moments"
                          className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
                        >
                          Moments
                        </NavLink>
                      </li>
                    )}
                    {/* Conditionally render "Create Events" and "My Events" for event promoters */}
                    {user.user_type === 'event_promoter' && (
                      <>
                        <li className="nav-item">
                          <NavLink
                            to="/create-event"
                            className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
                          >
                            Create Events
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink
                            to="/my-events"
                            className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
                          >
                            My Events
                          </NavLink>
                        </li>
                      </>
                    )}
                    <li className="nav-item dropdown" style={{ marginTop: '-5px' }}>
                      <button
                        className="nav-link dropdown-toggle d-flex align-items-center"
                        id="userDropdown"
                        data-bs-toggle="dropdown"
                        aria-expanded="true"
                        style={{ background: 'none', border: 'none', color: 'white' }}
                      >
                        <img
                          src={defaultProfile}
                          alt="Profile"
                          className="profile-icon"
                          style={{
                            width: '40px',
                            height: '40px',
                            borderRadius: '50%',
                            marginRight: '10px',
                          }}
                        />
                        <span className="username">{user.name}</span>
                      </button>
                      <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="userDropdown">
                        <li>
                          <NavLink className="dropdown-item" to="/profile">
                            Profile
                          </NavLink>
                        </li>
                        <li>
                          <NavLink className="dropdown-item" to="/profile">
                            Settings
                          </NavLink>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <button className="dropdown-item" onClick={logout}>
                            Log Out
                          </button>
                        </li>
                      </ul>
                    </li>
                  </>
                ) : (
                  <>
                    <li className="nav-item">
                      <NavLink
                        to="/events"
                        className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
                      >
                        Events
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/login" className="nav-link">
                        <button className="button">Log In/Sign Up</button>
                      </NavLink>
                    </li>
                  </>
                )}
              </ul>

              {/* Search Icon Button */}
              <button
                className="btn"
                id="searchIconBtn"
                aria-label="Search"
                style={{ color: 'white' }}
                onClick={() => setShowSearchModal(true)}
              >
                <i className="fa fa-search"></i>
              </button>
            </div>
          </div>
        </nav>
      </section>

      {/* Search Modal */}
      <SearchModal show={showSearchModal} onClose={handleSearchClose} />
    </>
  );
};

export default Header;
