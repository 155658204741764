// src/services/EventsService.js
import API from '../config';

export const fetchActiveEvents = async () => {
  try {
    const response = await fetch(`${API.EVENTS}/active-events`);
    const data = await response.json();
    if (data.success) {
      return data.events;
    } else {
      console.error('Error fetching events:', data.message);
      return [];
    }
  } catch (error) {
    console.error('Error fetching events:', error);
    return [];
  }
};

export const fetchMyEvents = async (token) => {
  try {
    const response = await fetch(`${API.EVENTS}/my-events`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    const data = await response.json();
    if (data.success) {
      return data.events;
    } else {
      console.error('Error fetching your events:', data.message);
      return [];
    }
  } catch (error) {
    console.error('Error fetching your events:', error);
    return [];
  }
};

// Implement deleteEvent
export const deleteEvent = async (eventId, token) => {
  try {
    const response = await fetch(`${API.EVENTS}/${eventId}/delete`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    return response.json();
  } catch (error) {
    console.error('Error deleting event:', error);
    return { success: false, message: 'Error deleting event' };
  }
};
