// src/pages/HomePage.js
import React from 'react';
import Carousel from '../components/Carousel';
import LatestEvents from '../components/LatestEvents';
import UpcomingEvents from '../components/UpcomingEvents';
import TopEvents from '../components/TopEvents';

const HomePage = () => {
  return (
    <div>
      <section id="center" className="center_home">
        <Carousel />
      </section>
      <LatestEvents />
      <UpcomingEvents />
      <TopEvents />
    </div>
  );
};

export default HomePage;
