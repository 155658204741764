// src/components/VerifyCodeForm.js
import React, { useState } from 'react';
import { verifyCode } from '../../services/AuthService';
import { useNavigate, useLocation } from 'react-router-dom';
import AuthLayout from '../AuthLayout'; 

const VerifyCodeForm = () => {
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email;

  if (!email) {
    navigate('/forgot-password');
  }

  const handleVerifyCode = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = await verifyCode(email, code);
    setLoading(false);

    if (data.success && data.token) {
      localStorage.setItem('resetToken', data.token);
      navigate('/reset-password');
    } else {
      setMessage(data.message || 'Invalid code.');
    }
  };

  return (
    <AuthLayout>
      <section id="verification-form" className="pt-4 pb-4 bg_grey">
      <br /><br /><br /><br /> 
        <div className="container-xl">
          <div className="row contact_22 mt-4">
            <div className="col-md-4"></div>
            <div className="col-md-8">
              <div className="contact_2l row">
                <div className="col-md-6">
                  <h4 align="center" className='text-white'>Verify Code</h4>
                </div>
              </div>
              <form onSubmit={handleVerifyCode}>
                <div className="contact_2l1 mt-3 row">
                  <div className="col-md-6">
                    <input
                      className="form-control"
                      placeholder="Enter verification code"
                      type="text"
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                      required
                    />
                  </div>
                </div>
                {message && <div className="mt-2">{message}</div>}
                <div className="contact_2l1 mt-3 row">
                  <div className="col-md-6">
                    <h6 align="center" className="mt-3 mb-0">
                      <button className="button" type="submit" disabled={loading}>
                        {loading ? 'Verifying...' : 'Verify'}
                      </button>
                    </h6>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <br /><br /><br /><br /> <br /><br />
      </section>
    </AuthLayout>
  );
};

export default VerifyCodeForm;
