// src/pages/MyEventsPage.js
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../context/AuthContext';
import { fetchMyEvents, deleteEvent } from '../services/EventsService'; // Ensure deleteEvent is implemented
import EventCard from '../components/EventCard';
import ReactPaginate from 'react-paginate';

const MyEventsPage = () => {
  const { user, updateUser } = useContext(AuthContext);
  const [myEvents, setMyEvents] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [eventsPerPage] = useState(6); // Adjust as needed
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getMyEvents = async () => {
      setLoading(true);
      try {
        const events = await fetchMyEvents(user.token);
        setMyEvents(events);
      } catch (err) {
        setError('Failed to fetch your events.');
      }
      setLoading(false);
    };

    if (user && user.user_type === 'event_promoter') {
      getMyEvents();
    }
  }, [user]);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const handleDelete = async (eventId) => {
    if (window.confirm('Are you sure you want to delete this event?')) {
      try {
        const response = await deleteEvent(eventId, user.token);
        if (response.success) {
          // Remove the deleted event from the state
          setMyEvents(myEvents.filter((event) => event.id !== eventId));
          alert('Event deleted successfully.');
        } else {
          alert('Failed to delete the event.');
        }
      } catch (err) {
        console.error(err);
        alert('An error occurred while deleting the event.');
      }
    }
  };

  const offset = currentPage * eventsPerPage;
  const currentEvents = myEvents.slice(offset, offset + eventsPerPage);
  const pageCount = Math.ceil(myEvents.length / eventsPerPage);

  if (!user || user.user_type !== 'event_promoter') {
    return (
      <div className="container mt-5">
        <h3>You do not have access to this page.</h3>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="container mt-5">
        <h3>Loading your events...</h3>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mt-5">
        <h3>{error}</h3>
      </div>
    );
  }

  return (
    <section id="my-events" className="pt-4 pb-5 bg-dark text-white">
        <br/><br/><br/><br/>
      <div className="container">
        <h2 className="mb-4">My Events</h2>
        {myEvents.length === 0 ? (
          <p>You have not created any events yet.</p>
        ) : (
          <>
            <div className="row">
              {currentEvents.map((event) => (
                <EventCard key={event.id} event={event} onDelete={handleDelete} />
              ))}
            </div>
            {/* Pagination Controls */}
            {pageCount > 1 && (
              <ReactPaginate
                previousLabel={'previous'}
                nextLabel={'next'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={'pagination justify-content-center'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                previousClassName={'page-item'}
                nextClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextLinkClassName={'page-link'}
                activeClassName={'active'}
              />
            )}
          </>
        )}
      </div>
    </section>
  );
};

export default MyEventsPage;
