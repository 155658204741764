// src/pages/ProfilePage.js
import React, { useContext, useState } from 'react';
import { AuthContext } from '../context/AuthContext';
import ProfileDetails from '../components/ProfileDetails';
import EditProfileForm from '../components/forms/EditProfileForm';

const ProfilePage = () => {
  const { user } = useContext(AuthContext);
  const [isEditing, setIsEditing] = useState(false);

  if (!user) {
    return <div>Please log in to view your profile.</div>;
  }

  return (
    <div>
      {isEditing ? (
        <EditProfileForm user={user} onCancel={() => setIsEditing(false)} />
      ) : (
        <ProfileDetails user={user} onEdit={() => setIsEditing(true)} />
      )}
    </div>
  );
};

export default ProfilePage;
