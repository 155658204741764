// src/components/MomentCard.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../assets/css/Moments.css';
const MomentCard = ({ moment }) => {
  const navigate = useNavigate();

  const handleView = (e) => {
    e.stopPropagation();
    navigate(`/moment/${moment.id}`);
  };

  const handleDownload = (e) => {
    e.stopPropagation();
    // Implement download logic here
    alert(`Downloading moment with ID: ${moment.id}`);
  };

  return (
    <div className="col-md-4 mb-4">
      <div
        className="moment-card"
        onClick={() => navigate(`/moment/${moment.id}`)}
        style={{ cursor: 'pointer' }}
      >
        <img src={moment.img} className="moment-img" alt={moment.title} />
        <div className="card-body">
          <h5 className="moment-title">{moment.title}</h5>
          <div className="moment-actions">
            <button className="btn-view" onClick={handleView}>
              View
            </button>
            <button className="btn-download" onClick={handleDownload}>
              Download
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MomentCard;
