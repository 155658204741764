// src/components/LoginForm.js
import React, { useState, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import AuthLayout from '../AuthLayout'; 

const LoginForm = () => {
  const { login } = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    const result = await login(email, password);
    setLoading(false);

    if (result.success) {
      navigate('/');
    } else {
      setError(result.message);
    }
  };

  return (
    <AuthLayout>
      <section id="contact" className="pt-4 pb-4 bg_grey">
        <br /><br /><br /><br /><br />
        <div className="container-xl">
          <div className="row contact_22 mt-4">
            <div className="col-md-4"></div>
            <div className="col-md-8">
              <div className="contact_2l row">
                <div className="col-md-6">
                  <h4 align="center" className='text-white'>LOG IN</h4>
                </div>
              </div>
              <form onSubmit={handleLogin}>
                <div className="contact_2l1 mt-3 row">
                  <div className="col-md-6">
                    <input
                      className="form-control"
                      placeholder="Email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="contact_2l1 mt-3 row">
                  <div className="col-md-6">
                    <input
                      className="form-control"
                      placeholder="Password"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                    <div align="right">
                      <Link to="/forgot-password" className="forgot-password" style={{ marginTop: '15px' }}>
                        Forgot Password?
                      </Link>
                    </div>
                  </div>
                </div>
                {error && <div className="text-danger mt-2">{error}</div>}
                <div className="contact_2l1 mt-3 row">
                  <div className="col-md-6">
                    <h6 align="center" className="mt-3 mb-0">
                      <button className="button" type="submit" disabled={loading}>
                        {loading ? 'Logging in...' : 'Login'}
                      </button>
                    </h6>
                    <br />
                    <div align="center" className='text-white'>
                      Don't have an account yet?{' '}
                      <Link to="/signup" className="col_red" style={{ fontSize: '20px', fontWeight: 'bold' }}>
                        Sign Up
                      </Link>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <br /><br /><br /><br /><br /><br />
      </section>
    </AuthLayout>
  );
};

export default LoginForm;
