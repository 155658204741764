// src/pages/EventPage.js
import React, { useContext, useState } from 'react';
import { EventContext } from '../context/EventContext';
import { AuthContext } from '../context/AuthContext'; // Import AuthContext to get the user data
import EventDetails from '../components/EventDetails';
import SelfiePickerModal from '../components/modals/SelfiePickerModal';
import LoadingModal from '../components/modals/LoadingModal';

const EventPage = () => {
  const { event, loading } = useContext(EventContext);
  const { user } = useContext(AuthContext); // Fetch the current logged-in user

  // State to control the visibility of the SelfiePickerModal
  const [showSelfieModal, setShowSelfieModal] = useState(false);

  if (loading) {
    return <LoadingModal show={true} message="Loading event..." />;
  }

  if (!event) {
    return <div>Event not found.</div>;
  }

  return (
    <div className="event-page">
      <EventDetails 
        event={event}
        onCheckIn={() => setShowSelfieModal(true)} // Pass the handler to open the modal
      />

      {/* Only render the SelfiePickerModal if the user is logged in and is an attendee */}
      {user && user.user_type === 'event_attendee' && (
        <SelfiePickerModal
          show={showSelfieModal}
          onClose={() => setShowSelfieModal(false)} // Close the modal by setting state to false
          eventId={String(event.id)} // Convert event.id to a string
          user={{ ...user, id: String(user.id), user_type: user.user_type }} // Ensure user.id and user_type are strings
        />
      )}

      {/* Display a message if no user is logged in */}
      {!user && (
        <div className="alert alert-warning">
          You need to be logged in to check-in with a selfie.
        </div>
      )}
    </div>
  );
};

export default EventPage;
