// src/components/modals/SearchModal.js
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'bootstrap'; // Import Bootstrap's Modal class
import API from '../../config'; // Import the configuration

const SearchModal = ({ show, onClose }) => {
  const modalRef = useRef(null);
  const modalInstance = useRef(null);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [status, setStatus] = useState(''); // 'loading', 'success', 'error'
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (modalRef.current) {
      // Initialize Bootstrap Modal
      modalInstance.current = new Modal(modalRef.current, {
        backdrop: 'static', // Prevent closing by clicking outside
        keyboard: true, // Allow closing with keyboard (e.g., Esc key)
      });

      // Event listener for when the modal is hidden
      modalRef.current.addEventListener('hidden.bs.modal', handleHidden);
    }

    return () => {
      // Cleanup on unmount
      if (modalInstance.current) {
        modalInstance.current.dispose();
      }
      if (modalRef.current) {
        modalRef.current.removeEventListener('hidden.bs.modal', handleHidden);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (modalInstance.current) {
      if (show) {
        modalInstance.current.show();
      } else {
        modalInstance.current.hide();
      }
    }
  }, [show]);

  const handleHidden = () => {
    if (onClose) {
      onClose();
    }
    resetState();
  };

  const resetState = () => {
    setSearchQuery('');
    setSearchResults([]);
    setStatus('');
    setMessage('');
  };

  const handleSearch = async () => {
    if (!searchQuery.trim()) {
      setStatus('error');
      setMessage('Please enter a search query.');
      return;
    }

    setStatus('loading');
    setMessage('Searching events...');
    setSearchResults([]);

    try {
      const response = await fetch(
        `${API.EVENTS}/search?query=${encodeURIComponent(searchQuery)}`
      );
      const data = await response.json();

      if (data.success) {
        if (data.events.length > 0) {
          setSearchResults(data.events);
          setStatus('success');
          setMessage('Search completed.');
        } else {
          setSearchResults([]);
          setStatus('error');
          setMessage('No results found.');
        }
      } else {
        setStatus('error');
        setMessage(data.message || 'Error searching events.');
      }
    } catch (error) {
      console.error('Error searching events:', error);
      setStatus('error');
      setMessage('There was an error searching for events.');
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSearch();
    }
  };

  const handleCardClick = (eventId) => {
    navigate(`/event/${eventId}`);
    modalInstance.current.hide(); // Close the modal after navigation
  };

  return (
    <>
      {/* Bootstrap Modal */}
      <div
        className="modal fade"
        id="searchModal"
        tabIndex="-1"
        aria-labelledby="searchModalLabel"
        aria-hidden="true"
        ref={modalRef}
      >
        <div className="modal-dialog modal-lg modal-dialog-top"> {/* Added 'modal-dialog-top' */}
          <div className="modal-content custom-modal bg-dark text-white">
            <div className="modal-header">
              <h5 className="modal-title" id="searchModalLabel">
                Search Events
              </h5>
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
             {/* Search Input with Button */}
             <div className="input-group">
                <input
                  type="text"
                  className="form-control bg-black text-white"
                  id="searchQuery"
                  placeholder="Search for events..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onKeyPress={handleKeyPress}
                  required
                />
                <button
                  className="btn text-white bg_red rounded-0 border-0"
                  type="button"
                  id="searchButton"
                  onClick={handleSearch}
                >
                  Search
                </button>
              </div>

              {/* Status Messages */}
              {status === 'loading' && (
                <div className="text-center text-white mt-3">
                  <div className="spinner-border text-light" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                  <p className="mt-2">{message}</p>
                </div>
              )}
              {status === 'success' && (
                <div className="alert alert-success text-center mt-3" role="alert">
                  {message}
                </div>
              )}
              {status === 'error' && (
                <div className="alert alert-danger text-center mt-3" role="alert">
                  {message}
                </div>
              )}

              {/* Search Results */}
              <div id="searchResults" className="mt-4">
                {searchResults.length > 0 &&
                  searchResults.map((event) => (
                    <div
                      key={event.id}
                      className="card mb-3 custom-card bg-secondary text-white shadow-lg"
                      style={{ borderRadius: '15px', overflow: 'hidden', cursor: 'pointer' }}
                      onClick={() => handleCardClick(event.id)}
                    >
                      <div className="row g-0">
                        <div className="col-md-4">
                          <img
                            src={
                              event.event_graphic
                                ? `${API.UPLOADS}/${event.event_graphic}`
                                : '/img/default-event.jpg' // Ensure this path is correct
                            }
                            className="img-fluid rounded-start"
                            alt="Event Image"
                            style={{
                              height: '100%',
                              objectFit: 'cover',
                              borderTopLeftRadius: '15px',
                              borderBottomLeftRadius: '15px',
                            }}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = '/path_to_default_image.jpg'; // Replace with your default image path
                            }}
                          />
                        </div>
                        <div className="col-md-8">
                          <div className="card-body">
                            <h5 className="card-title fw-bold">{event.event_title}</h5>
                            <p className="card-text text-truncate" style={{ maxWidth: '90%' }}>
                              {event.event_summary || event.event_description}
                            </p>
                            <p className="card-text">
                              <i className="fa fa-map-marker-alt"></i> {event.location}
                            </p>
                            <p className="card-text">
                              <i className="fa fa-calendar-alt"></i>{' '}
                              {new Date(event.event_date).toLocaleDateString('en-US', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                              })}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                {status === 'success' && searchResults.length === 0 && (
                  <p className="text-center text-white">No results found.</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

SearchModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SearchModal;
