// src/services/axiosInstance.js
import axios from 'axios';
import API from '../config';

const axiosInstance = axios.create({
  baseURL: API.EVENTS, 
});

// Add a request interceptor to include the token
axiosInstance.interceptors.request.use(
  (config) => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const user = JSON.parse(storedUser);
      if (user.token) {
        config.headers.Authorization = `Bearer ${user.token}`;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
