// src/components/EventCard.js
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import API from '../config'; // Import the configuration

const EventCard = ({ event, onDelete = null }) => {
  return (
    <div className="col-md-3 col-6 mb-4">
      <div className="event-card position-relative">
        {/* Event Image */}
        <div className="event-card-img">
          <Link to={`/event/${event.id}`}>
            <img
              src={`${API.UPLOADS}/${event.event_graphic}`}
              alt={event.event_title}
              className="img-fluid w-100"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = '/path_to_default_image.jpg'; // Replace with your default image path
              }}
            />
          </Link>
          {/* Overlay Icon */}
          <div className="event-overlay-icon text-center">
            <Link to={`/event/${event.id}`}>
            </Link>
          </div>
        </div>
        {/* Event Details */}
        <div className="event-card-body bg_grey p-3">
          <h5 className="event-title">
            <Link to={`/event/${event.id}`} className="text_red">
              {event.event_title}
            </Link>
          </h5>
          <p className="event-summary mb-2">{event.event_summary}</p>
          <p className="event-views mb-0">1 Views</p> {/* Replace with actual view count if available */}
          {/* Action Buttons (Optional) */}
          {onDelete && (
            <div className="mt-3">
              <Link to={`/event/${event.id}`} className="btn btn-warning btn-sm me-2">
                Edit
              </Link>
              <button
                className="btn btn-danger btn-sm"
                onClick={() => onDelete(event.id)}
              >
                Delete
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

// PropTypes for type checking
EventCard.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.number.isRequired,
    event_graphic: PropTypes.string.isRequired,
    event_title: PropTypes.string.isRequired,
    event_summary: PropTypes.string.isRequired,
    // Add other event properties if needed
  }).isRequired,
  onDelete: PropTypes.func, // Function to handle deletion
};

export default EventCard;
