// src/components/modals/UploadFootageModal.js
import React, { useState, useEffect, useRef } from 'react';
import { FootageService } from '../../services/FootageService';
import PropTypes from 'prop-types';
import * as bootstrap from 'bootstrap'; // Import Bootstrap JS

const UploadFootageModal = ({ eventId, show, handleClose }) => {
  const [footageFiles, setFootageFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadMessage, setUploadMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const modalRef = useRef(null);
  const modalInstance = useRef(null);

  useEffect(() => {
    if (modalRef.current) {
      // Initialize Bootstrap Modal
      modalInstance.current = new bootstrap.Modal(modalRef.current, {
        backdrop: 'static', // Prevent closing by clicking outside
        keyboard: false,    // Prevent closing with Esc key
      });

      if (show) {
        modalInstance.current.show();
      }

      // Event listener for when the modal is hidden
      const handleHidden = () => {
        resetState();
        handleClose(); // Notify parent to update the state
      };
      modalRef.current.addEventListener('hidden.bs.modal', handleHidden);

      // Cleanup event listener on unmount
      return () => {
        if (modalInstance.current) {
          modalInstance.current.dispose();
        }
        if (modalRef.current) {
          modalRef.current.removeEventListener('hidden.bs.modal', handleHidden);
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]); // Re-run when `show` prop changes

  const resetState = () => {
    setFootageFiles([]);
    setUploadProgress(0);
    setUploadMessage('');
    setIsSuccess(false);
    setIsError(false);
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    setUploadProgress(0);
    setUploadMessage('Uploading footage...');
    setIsSuccess(false);
    setIsError(false);

    const formData = new FormData();
    formData.append('event_id', eventId);

    // Append each selected file to the FormData
    Array.from(footageFiles).forEach((file) => {
      formData.append('footage', file);
    });

    try {
      const result = await FootageService.uploadFootage(formData, (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setUploadProgress(percentCompleted);
      });

      if (result.success) {
        setUploadMessage('Footage uploaded successfully!');
        setIsSuccess(true);
        setIsError(false);
        setFootageFiles([]); // Clear the file input

        // Automatically close the modal after a short delay (e.g., 2 seconds)
        setTimeout(() => {
          if (modalInstance.current) {
            modalInstance.current.hide();
          }
        }, 2000);
      } else {
        setUploadMessage('Error uploading footage: ' + result.message);
        setIsError(true);
      }
    } catch (error) {
      console.error('Error uploading footage:', error);
      setUploadMessage('There was an error uploading the footage.');
      setIsError(true);
    }
  };

  return (
    <div
      className="modal fade"
      id="uploadFootageModal"
      tabIndex="-1"
      aria-labelledby="uploadFootageLabel"
      aria-hidden="true"
      ref={modalRef}
    >
      <div className="modal-dialog modal-lg modal-dialog-top">
        <div className="modal-content custom-modal bg-dark text-white">
          <div className="modal-header">
            <h5 className="modal-title" id="uploadFootageLabel">
              Upload Event Footage
            </h5>
            <button
              type="button"
              className="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleUpload}>
              <div className="mb-3" align='start'>
                <label className="form-label" >Upload Footage</label>
                <input
                  className="form-control"
                  type="file"
                  accept="video/*, image/*" // Allow both video and image files
                  multiple // Enable multiple file selection
                  required
                  onChange={(e) => setFootageFiles(e.target.files)}
                />
              </div>
              {/* Removed Footage Description and Tags Fields */}
              <div className="d-grid gap-2">
                <button
                  type="submit"
                  className="btn text-white bg_red rounded-2 border-0"
                >
                  Upload Footage
                </button>
              </div>
            </form>

            {/* Show upload progress if uploading */}
            {uploadMessage && (
              <div className="mt-3" align='center'>
                {isSuccess && (
                  <div className="alert alert-success" role="alert">
                    {uploadMessage}
                  </div>
                )}
                {isError && (
                  <div className="alert alert-danger" role="alert">
                    {uploadMessage}
                  </div>
                )}
                {!isSuccess && !isError && uploadProgress > 0 && (
                  <div>
                    <div className="progress">
                      <div
                        className="progress-bar progress-bar-striped progress-bar-animated"
                        role="progressbar"
                        style={{ width: `${uploadProgress}%` }}
                        aria-valuenow={uploadProgress}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        {uploadProgress}%
                      </div>
                    </div>
                    <p className="mt-2">{uploadMessage}</p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

UploadFootageModal.propTypes = {
  eventId: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default UploadFootageModal;
