// src/components/ResetPasswordForm.js
import React, { useState } from 'react';
import { resetPassword } from '../../services/AuthService';
import { useNavigate } from 'react-router-dom';
import AuthLayout from '../AuthLayout'; 

const ResetPasswordForm = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();

  const resetToken = localStorage.getItem('resetToken');

  if (!resetToken) {
    navigate('/forgot-password');
  }

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (password !== confirmPassword) {
      setMessage('Passwords do not match!');
      setLoading(false);
      return;
    }

    const data = await resetPassword(password, resetToken);
    setLoading(false);

    if (data.success) {
      localStorage.removeItem('resetToken');
      navigate('/login');
    } else {
      setMessage(data.message || 'Error resetting password.');
    }
  };

  return (
    <AuthLayout>
      <section id="reset-password-form" className="pt-4 pb-4 bg_grey">
      <br /><br /><br /><br /> <br /><br />
        <div className="container-xl">
          <div className="row contact_22 mt-4">
            <div className="col-md-4"></div>
            <div className="col-md-8">
              <div className="contact_2l row">
                <div className="col-md-6">
                  <h4 align="center" className='text-white'>Reset Password</h4>
                </div>
              </div>
              <form onSubmit={handleResetPassword}>
                <div className="contact_2l1 mt-3 row">
                  <div className="col-md-6">
                    <input
                      className="form-control"
                      placeholder="New Password"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="contact_2l1 mt-3 row">
                  <div className="col-md-6">
                    <input
                      className="form-control"
                      placeholder="Confirm New Password"
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                    />
                  </div>
                </div>
                {message && <div className="mt-2">{message}</div>}
                <div className="contact_2l1 mt-3 row">
                  <div className="col-md-6">
                    <h6 align="center" className="mt-3 mb-0">
                      <button className="button" type="submit" disabled={loading}>
                        {loading ? 'Resetting...' : 'Reset Password'}
                      </button>
                    </h6>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <br /><br /><br /><br /> <br /><br />
      </section>
    </AuthLayout>
  );
};

export default ResetPasswordForm;
