// src/components/AuthLayout.js
import React from 'react';
import '../assets/css/Auth.css';
const AuthLayout = ({ children }) => {
  return (
    <div className="auth-container">
      <main>{children}</main>
    </div>
  );
};

export default AuthLayout;
