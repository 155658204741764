// src/services/CheckInService.js
import API from '../config';

const API_URL = API.CHECKIN;

export const CheckInService = {
  checkIn: async (formData) => {
    const response = await fetch(`${API_URL}/checkin`, {
      method: 'POST',
      body: formData,
    });
    return response.json();
  },
  getCheckInStatus: async (userId, eventId) => {
    const response = await fetch(`${API_URL}/checkin-status/${userId}/${eventId}`);
    return response.json();
  },
};
