// src/components/SignupForm.js
import React, { useState, useEffect } from 'react';
import { register } from '../../services/AuthService';
import { Link, useNavigate } from 'react-router-dom';
import AuthLayout from '../AuthLayout'; 

const SignupForm = () => {
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    user_type: 'event_attendee', // Default to 'event_attendee'
    business_name: '',
    business_type: '',
    business_address: '',
    location: '', // Only for event_attendee
  });
  const [is18, setIs18] = useState(false); // State for age verification
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // New state variables for password validation
  const [passwordValidations, setPasswordValidations] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false,
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    setIs18(e.target.checked);
  };

  const handlePasswordChange = (password) => {
    // Define password validation rules
    const validations = {
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /[0-9]/.test(password),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    };
    setPasswordValidations(validations);
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    // Age Verification
    if (!is18) {
      setError('You must be at least 18 years old to sign up.');
      setLoading(false);
      return;
    }

    // Password Confirmation
    if (userData.password !== userData.confirmPassword) {
      setError('Passwords do not match!');
      setLoading(false);
      return;
    }

    // Check if all password validations pass
    const allValid = Object.values(passwordValidations).every(Boolean);
    if (!allValid) {
      setError('Password does not meet the required criteria.');
      setLoading(false);
      return;
    }

    // Prepare data to send
    const dataToSend = { ...userData };

    // Conditional Data Handling based on user_type
    if (userData.user_type === 'event_attendee') {
      // For event_attendee, only include location (City & State)
      delete dataToSend.business_name;
      delete dataToSend.business_type;
      delete dataToSend.business_address;
    } else if (userData.user_type === 'event_promoter') {
      // For event_promoter, remove location and ensure business_address is present
      delete dataToSend.location;
      // Optionally, validate business fields here
    }

    const data = await register(dataToSend);

    setLoading(false);

    if (data.user) {
      navigate('/login');
    } else {
      setError(data.message || 'Sign-up failed');
    }
  };

  // Sample list of US cities and states
  const usStates = [
    { name: 'Alabama', abbreviation: 'AL' },
    { name: 'Alaska', abbreviation: 'AK' },
    { name: 'Arizona', abbreviation: 'AZ' },
    { name: 'Arkansas', abbreviation: 'AR' },
    { name: 'California', abbreviation: 'CA' },
    { name: 'Colorado', abbreviation: 'CO' },
    { name: 'Connecticut', abbreviation: 'CT' },
    { name: 'Delaware', abbreviation: 'DE' },
    { name: 'Florida', abbreviation: 'FL' },
    { name: 'Georgia', abbreviation: 'GA' },
    { name: 'Hawaii', abbreviation: 'HI' },
    { name: 'Idaho', abbreviation: 'ID' },
    { name: 'Illinois', abbreviation: 'IL' },
    { name: 'Indiana', abbreviation: 'IN' },
    { name: 'Iowa', abbreviation: 'IA' },
    { name: 'Kansas', abbreviation: 'KS' },
    { name: 'Kentucky', abbreviation: 'KY' },
    { name: 'Louisiana', abbreviation: 'LA' },
    { name: 'Maine', abbreviation: 'ME' },
    { name: 'Maryland', abbreviation: 'MD' },
    { name: 'Massachusetts', abbreviation: 'MA' },
    { name: 'Michigan', abbreviation: 'MI' },
    { name: 'Minnesota', abbreviation: 'MN' },
    { name: 'Mississippi', abbreviation: 'MS' },
    { name: 'Missouri', abbreviation: 'MO' },
    { name: 'Montana', abbreviation: 'MT' },
    { name: 'Nebraska', abbreviation: 'NE' },
    { name: 'Nevada', abbreviation: 'NV' },
    { name: 'New Hampshire', abbreviation: 'NH' },
    { name: 'New Jersey', abbreviation: 'NJ' },
    { name: 'New Mexico', abbreviation: 'NM' },
    { name: 'New York', abbreviation: 'NY' },
    { name: 'North Carolina', abbreviation: 'NC' },
    { name: 'North Dakota', abbreviation: 'ND' },
    { name: 'Ohio', abbreviation: 'OH' },
    { name: 'Oklahoma', abbreviation: 'OK' },
    { name: 'Oregon', abbreviation: 'OR' },
    { name: 'Pennsylvania', abbreviation: 'PA' },
    { name: 'Rhode Island', abbreviation: 'RI' },
    { name: 'South Carolina', abbreviation: 'SC' },
    { name: 'South Dakota', abbreviation: 'SD' },
    { name: 'Tennessee', abbreviation: 'TN' },
    { name: 'Texas', abbreviation: 'TX' },
    { name: 'Utah', abbreviation: 'UT' },
    { name: 'Vermont', abbreviation: 'VT' },
    { name: 'Virginia', abbreviation: 'VA' },
    { name: 'Washington', abbreviation: 'WA' },
    { name: 'West Virginia', abbreviation: 'WV' },
    { name: 'Wisconsin', abbreviation: 'WI' },
    { name: 'Wyoming', abbreviation: 'WY' },
  ];

  const usCities = {
    'AL': ['Birmingham', 'Montgomery', 'Mobile', 'Huntsville', 'Tuscaloosa'],
    'AK': ['Anchorage', 'Fairbanks', 'Juneau', 'Sitka', 'Ketchikan'],
    'AZ': ['Phoenix', 'Tucson', 'Mesa', 'Chandler', 'Scottsdale'],
    'AR': ['Little Rock', 'Fort Smith', 'Fayetteville', 'Springdale', 'Jonesboro'],
    'CA': ['Los Angeles', 'San Diego', 'San Jose', 'San Francisco', 'Fresno', 'Sacramento', 'Long Beach', 'Oakland', 'Bakersfield', 'Anaheim'],
    'CO': ['Denver', 'Colorado Springs', 'Aurora', 'Fort Collins', 'Lakewood', 'Thornton', 'Arvada', 'Westminster', 'Pueblo', 'Centennial'],
    'CT': ['Bridgeport', 'New Haven', 'Stamford', 'Hartford', 'Waterbury', 'Norwalk', 'Danbury', 'New Britain', 'Bristol', 'Meriden'],
    'DE': ['Wilmington', 'Dover', 'Newark', 'Middletown', 'Smyrna'],
    'FL': ['Jacksonville', 'Miami', 'Tampa', 'Orlando', 'St. Petersburg', 'Hialeah', 'Tallahassee', 'Port St. Lucie', 'Cape Coral', 'Fort Lauderdale'],
    'GA': ['Atlanta', 'Augusta', 'Columbus', 'Macon', 'Savannah', 'Athens', 'Sandy Springs', 'Roswell', 'Johns Creek', 'Albany'],
    'HI': ['Honolulu', 'Hilo', 'Kailua', 'Kapolei', 'Kaneohe'],
    'ID': ['Boise', 'Meridian', 'Nampa', 'Idaho Falls', 'Pocatello', 'Caldwell', 'Twin Falls', 'Lewiston', 'Rexburg', 'Post Falls'],
    'IL': ['Chicago', 'Aurora', 'Naperville', 'Joliet', 'Rockford', 'Springfield', 'Elgin', 'Peoria', 'Champaign', 'Waukegan'],
    'IN': ['Indianapolis', 'Fort Wayne', 'Evansville', 'South Bend', 'Carmel', 'Fishers', 'Bloomington', 'Hammond', 'Gary', 'Lafayette'],
    'IA': ['Des Moines', 'Cedar Rapids', 'Davenport', 'Sioux City', 'Iowa City', 'Waterloo', 'Council Bluffs', 'Ames', 'Dubuque', 'West Des Moines'],
    'KS': ['Wichita', 'Overland Park', 'Kansas City', 'Olathe', 'Topeka', 'Lawrence', 'Shawnee', 'Manhattan', 'Lenexa', 'Salina'],
    'KY': ['Louisville', 'Lexington', 'Bowling Green', 'Owensboro', 'Covington', 'Richmond', 'Georgetown', 'Florence', 'Hopkinsville', 'Elizabethtown'],
    'LA': ['New Orleans', 'Baton Rouge', 'Shreveport', 'Lafayette', 'Lake Charles', 'Kenner', 'Bossier City', 'Monroe', 'Alexandria', 'Houma'],
    'ME': ['Portland', 'Lewiston', 'Bangor', 'South Portland', 'Auburn', 'Biddeford', 'Sanford', 'Augusta', 'Brunswick', 'Saco'],
    'MD': ['Baltimore', 'Columbia', 'Germantown', 'Silver Spring', 'Waldorf', 'Ellicott City', 'Frederick', 'Glen Burnie', 'Rockville', 'Gaithersburg'],
    'MA': ['Boston', 'Worcester', 'Springfield', 'Lowell', 'Cambridge', 'Brockton', 'New Bedford', 'Quincy', 'Lynn', 'Fall River'],
    'MI': ['Detroit', 'Grand Rapids', 'Warren', 'Sterling Heights', 'Ann Arbor', 'Lansing', 'Flint', 'Dearborn', 'Livonia', 'Westland'],
    'MN': ['Minneapolis', 'Saint Paul', 'Rochester', 'Duluth', 'Bloomington', 'Brooklyn Park', 'Plymouth', 'Maple Grove', 'Woodbury', 'Eagan'],
    'MS': ['Jackson', 'Gulfport', 'Southaven', 'Hattiesburg', 'Biloxi', 'Meridian', 'Tupelo', 'Olive Branch', 'Horn Lake', 'Greenville'],
    'MO': ['Kansas City', 'Saint Louis', 'Springfield', 'Independence', 'Columbia', 'Lee\'s Summit', 'O\'Fallon', 'St. Joseph', 'St. Charles', 'St. Peters'],
    'MT': ['Billings', 'Missoula', 'Great Falls', 'Bozeman', 'Butte', 'Helena', 'Kalispell', 'Havre', 'Anaconda', 'Miles City'],
    'NE': ['Omaha', 'Lincoln', 'Bellevue', 'Grand Island', 'Kearney', 'Fremont', 'Hastings', 'Norfolk', 'North Platte', 'Scottsbluff'],
    'NV': ['Las Vegas', 'Henderson', 'Reno', 'North Las Vegas', 'Sparks', 'Carson City', 'Fernley', 'Elko', 'Mesquite', 'Boulder City'],
    'NH': ['Manchester', 'Nashua', 'Concord', 'Dover', 'Rochester', 'Salem', 'Merrimack', 'Londonderry', 'Keene', 'Portsmouth'],
    'NJ': ['Newark', 'Jersey City', 'Paterson', 'Elizabeth', 'Edison', 'Woodbridge', 'Lakewood', 'Toms River', 'Hamilton', 'Trenton'],
    'NM': ['Albuquerque', 'Las Cruces', 'Rio Rancho', 'Santa Fe', 'Roswell', 'Farmington', 'Clovis', 'Hobbs', 'Alamogordo', 'Carlsbad'],
    'NY': ['New York City', 'Buffalo', 'Rochester', 'Yonkers', 'Syracuse', 'Albany', 'New Rochelle', 'Mount Vernon', 'Schenectady', 'Utica'],
    'NC': ['Charlotte', 'Raleigh', 'Greensboro', 'Durham', 'Winston-Salem', 'Fayetteville', 'Cary', 'Wilmington', 'High Point', 'Asheville'],
    'ND': ['Fargo', 'Bismarck', 'Grand Forks', 'Minot', 'West Fargo', 'Williston', 'Dickinson', 'Mandan', 'Jamestown', 'Wahpeton'],
    'OH': ['Columbus', 'Cleveland', 'Cincinnati', 'Toledo', 'Akron', 'Dayton', 'Parma', 'Canton', 'Youngstown', 'Lorain'],
    'OK': ['Oklahoma City', 'Tulsa', 'Norman', 'Broken Arrow', 'Edmond', 'Moore', 'Midwest City', 'Enid', 'Stillwater', 'Lawton'],
    'OR': ['Portland', 'Salem', 'Eugene', 'Gresham', 'Hillsboro', 'Beaverton', 'Bend', 'Medford', 'Springfield', 'Corvallis'],
    'PA': ['Philadelphia', 'Pittsburgh', 'Allentown', 'Erie', 'Reading', 'Scranton', 'Bethlehem', 'Lancaster', 'Harrisburg', 'York'],
    'RI': ['Providence', 'Warwick', 'Cranston', 'Pawtucket', 'East Providence', 'Woonsocket', 'Coventry', 'Cumberland', 'North Providence', 'South Kingstown'],
    'SC': ['Columbia', 'Charleston', 'North Charleston', 'Mount Pleasant', 'Rock Hill', 'Greenville', 'Summerville', 'Sumter', 'Hilton Head Island', 'Florence'],
    'SD': ['Sioux Falls', 'Rapid City', 'Aberdeen', 'Brookings', 'Watertown', 'Mitchell', 'Yankton', 'Pierre', 'Vermillion', 'Huron'],
    'TN': ['Nashville', 'Memphis', 'Knoxville', 'Chattanooga', 'Clarksville', 'Murfreesboro', 'Franklin', 'Jackson', 'Johnson City', 'Bartlett'],
    'TX': ['Houston', 'San Antonio', 'Dallas', 'Austin', 'Fort Worth', 'El Paso', 'Arlington', 'Corpus Christi', 'Plano', 'Laredo'],
    'UT': ['Salt Lake City', 'West Valley City', 'Provo', 'West Jordan', 'Orem', 'Sandy', 'Ogden', 'St. George', 'Layton', 'Taylorsville'],
    'VT': ['Burlington', 'South Burlington', 'Rutland', 'Barre', 'Montpelier', 'St. Albans', 'Essex Junction', 'Bennington', 'Brattleboro', 'Barre Town'],
    'VA': ['Virginia Beach', 'Norfolk', 'Chesapeake', 'Richmond', 'Newport News', 'Alexandria', 'Hampton', 'Roanoke', 'Portsmouth', 'Suffolk'],
    'WA': ['Seattle', 'Spokane', 'Tacoma', 'Vancouver', 'Bellevue', 'Kent', 'Everett', 'Renton', 'Kirkland', 'Bellingham'],
    'WV': ['Charleston', 'Huntington', 'Morgantown', 'Parkersburg', 'Wheeling', 'Weirton', 'Fairmont', 'Martinsburg', 'South Charleston', 'Clarksburg'],
    'WI': ['Milwaukee', 'Madison', 'Green Bay', 'Kenosha', 'Racine', 'Appleton', 'Waukesha', 'Eau Claire', 'Oshkosh', 'Janesville'],
    'WY': ['Cheyenne', 'Casper', 'Laramie', 'Gillette', 'Rock Springs', 'Sheridan', 'Green River', 'Evanston', 'Riverton', 'Rawlins'],
  };
  

  // State management for dynamic city dropdown (for event_attendee)
  const [selectedState, setSelectedState] = useState('');
  const [cities, setCities] = useState([]);

  const handleStateChange = (e) => {
    const state = e.target.value;
    setSelectedState(state);
    setUserData({ ...userData, location: '' }); // Reset location when state changes

    // Fetch cities based on state
    const stateCities = usCities[state] || [];
    setCities(stateCities);
  };

  // Effect to validate password whenever it changes
  useEffect(() => {
    handlePasswordChange(userData.password);
  }, [userData.password]);

  // Function to render password validation indicators
  const renderPasswordValidation = () => {
    return (
      <ul className="password-validations">
        <li className={passwordValidations.length ? 'valid' : 'invalid'}>
          {passwordValidations.length ? <i className="fas fa-check"></i> : <i className="fas fa-times"></i>} At least 8 characters
        </li>
        <li className={passwordValidations.uppercase ? 'valid' : 'invalid'}>
          {passwordValidations.uppercase ? <i className="fas fa-check"></i> : <i className="fas fa-times"></i>} At least one uppercase letter
        </li>
        <li className={passwordValidations.lowercase ? 'valid' : 'invalid'}>
          {passwordValidations.lowercase ? <i className="fas fa-check"></i> : <i className="fas fa-times"></i>} At least one lowercase letter
        </li>
        <li className={passwordValidations.number ? 'valid' : 'invalid'}>
          {passwordValidations.number ? <i className="fas fa-check"></i> : <i className="fas fa-times"></i>} At least one number
        </li>
        <li className={passwordValidations.specialChar ? 'valid' : 'invalid'}>
          {passwordValidations.specialChar ? <i className="fas fa-check"></i> : <i className="fas fa-times"></i>} At least one special character (e.g., !@#$%^&*)
        </li>
      </ul>
    );
  };

  

  return (
    <AuthLayout>
      <section id="signup-form" className="pt-4 pb-4 bg_grey">
        {/* Added spacing to match existing design */}
        <br /><br /><br /><br />
        <div className="container-xl">
          <div className="row contact_22 mt-4">
            <div className="col-md-4"></div>
            <div className="col-md-8">
              <div className="contact_2l row">
                <div className="col-md-6">
                  <h4 className='text-white text-center'>SIGN UP</h4>
                </div>
              </div>
              <form onSubmit={handleSignup}>

                {/* User Type */}
                <div className="contact_2l1 mt-3 row">
                  <div className="col-md-6">
                    <select
                      name="user_type"
                      className="form-select" // Changed to 'form-select' for Bootstrap 5
                      value={userData.user_type}
                      onChange={handleChange}
                      required
                    >
                      {/* Removed the disabled option since we're setting a default */}
                      <option value="event_promoter">Event Promoter</option>
                      <option value="event_attendee">Event Attendee</option>
                    </select>
                  </div>
                </div>

                {/* Conditional Fields based on User Type */}
                {userData.user_type === 'event_promoter' && (
                  <>
                    {/* Name */}
                    <div className="contact_2l1 mt-3 row">
                      <div className="col-md-6">
                        <input
                          name="name"
                          className="form-control"
                          placeholder="Full Name"
                          type="text"
                          value={userData.name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>

                    {/* Email */}
                    <div className="contact_2l1 mt-3 row">
                      <div className="col-md-6">
                        <input
                          name="email"
                          className="form-control"
                          placeholder="Email"
                          type="email"
                          value={userData.email}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>

                    {/* Password */}
                    <div className="contact_2l1 mt-3 row">
                      <div className="col-md-6">
                        <input
                          name="password"
                          className="form-control"
                          placeholder="Password"
                          type="password"
                          value={userData.password}
                          onChange={handleChange}
                          required
                        />
                        {/* Password Instructions */}
                        {renderPasswordValidation()}
                      </div>
                    </div>

                    {/* Confirm Password */}
                    <div className="contact_2l1 mt-3 row">
                      <div className="col-md-6">
                        <input
                          name="confirmPassword"
                          className="form-control"
                          placeholder="Confirm Password"
                          type="password"
                          value={userData.confirmPassword}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>

                    {/* Business Name */}
                    <div className="contact_2l1 mt-3 row">
                      <div className="col-md-6">
                        <input
                          name="business_name"
                          className="form-control"
                          placeholder="Business Name"
                          type="text"
                          value={userData.business_name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>

                    {/* Business Type */}
                    <div className="contact_2l1 mt-3 row">
                      <div className="col-md-6">
                        <input
                          name="business_type"
                          className="form-control"
                          placeholder="Business Type"
                          type="text"
                          value={userData.business_type}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>

                    {/* Business Address */}
                    <div className="contact_2l1 mt-3 row">
                      <div className="col-md-6">
                        <input
                          name="business_address"
                          className="form-control"
                          placeholder="Business Address"
                          type="text"
                          value={userData.business_address}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                  </>
                )}

                {userData.user_type === 'event_attendee' && (
                  <>
                    {/* Name */}
                    <div className="contact_2l1 mt-3 row">
                      <div className="col-md-6">
                        <input
                          name="name"
                          className="form-control"
                          placeholder="Full Name"
                          type="text"
                          value={userData.name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>

                    {/* Email */}
                    <div className="contact_2l1 mt-3 row">
                      <div className="col-md-6">
                        <input
                          name="email"
                          className="form-control"
                          placeholder="Email"
                          type="email"
                          value={userData.email}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>

                    {/* Password */}
                    <div className="contact_2l1 mt-3 row">
                      <div className="col-md-6">
                        <input
                          name="password"
                          className="form-control"
                          placeholder="Password"
                          type="password"
                          value={userData.password}
                          onChange={handleChange}
                          required
                        />
                        {/* Password Instructions */}
                        {renderPasswordValidation()}
                      </div>
                    </div>

                    {/* Confirm Password */}
                    <div className="contact_2l1 mt-3 row">
                      <div className="col-md-6">
                        <input
                          name="confirmPassword"
                          className="form-control"
                          placeholder="Confirm Password"
                          type="password"
                          value={userData.confirmPassword}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>

                    {/* State Dropdown */}
                    <div className="contact_2l1 mt-3 row">
                      <div className="col-md-6">
                        <select
                          name="state"
                          className="form-select"
                          value={selectedState}
                          onChange={handleStateChange}
                          required
                        >
                          <option value="" disabled>
                            Select State
                          </option>
                          {usStates.map(state => (
                            <option key={state.abbreviation} value={state.abbreviation}>
                              {state.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    {/* City Dropdown */}
                    <div className="contact_2l1 mt-3 row">
                      <div className="col-md-6">
                        <select
                          name="location"
                          className="form-select"
                          value={userData.location}
                          onChange={handleChange}
                          required
                          disabled={!selectedState}
                        >
                          <option value="" disabled>
                            {selectedState ? 'Select City' : 'Select State First'}
                          </option>
                          {cities.map(city => (
                            <option
                              key={`${city}, ${selectedState}`}
                              value={`${city}, ${selectedState}`}
                            >
                              {city}, {selectedState}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </>
                )}

                {/* Age Verification Checkbox */}
                <div className="contact_2l1 mt-3 row">
                  <div className="col-md-6">
                    <label className="custom-checkbox">
                      <input
                        type="checkbox"
                        checked={is18}
                        onChange={handleCheckboxChange}
                        required
                      />
                      <span className="checkmark"></span>
                      I am 18 years or older
                    </label>
                  </div>
                </div>

                {/* Error Message */}
                {error && <div className="text-danger mt-2">{error}</div>}

                {/* Submit Button */}
                <div className="contact_2l1 mt-3 row">
                  <div className="col-md-6">
                    <h6 className="mt-3 mb-0 text-center">
                      <button className="button" type="submit" disabled={loading}>
                        {loading ? 'Signing up...' : 'Sign Up'}
                      </button>
                    </h6>
                    <br />
                    <div className='text-white text-center'>
                      Already have an account?{' '}
                      <Link to="/login" className="col_red" style={{ fontSize: '20px', fontWeight: 'bold' }}>
                        Login
                      </Link>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* Added spacing to match existing design */}
        <br /><br /><br /><br />
      </section>
    </AuthLayout>
  );
};

export default SignupForm;
