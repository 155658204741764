// src/services/FootageService.js
import axios from 'axios';
import API from '../config';

export const FootageService = {
  uploadFootage: (formData, onUploadProgress) => {
    return axios.post(`${API.FOOTAGE}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    })
    .then(response => response.data)
    .catch(error => {
      console.error('Error uploading footage:', error);
      const message = error.response?.data?.message || error.message;
      return { success: false, message };
    });
  },
  checkFootageStatus: async (eventId) => {
    try {
      const response = await axios.get(`${API.FOOTAGE}/status/${eventId}`);
      return response.data;
    } catch (error) {
      console.error('Error checking footage status:', error);
      const message = error.response?.data?.message || error.message;
      return { success: false, message };
    }
  },
};
