// src/components/EditProfileForm.js
import React, { useState, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { updateProfile } from '../../services/AuthService';
import LoadingModal from '../modals/LoadingModal';
import defaultProfile from '../../assets/img/default-profile.jpg';

const EditProfileForm = ({ user, onCancel }) => {
  const { updateUser } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    location: user.location || '',
    business_name: user.business?.business_name || '',
    business_contact_name: user.business?.business_contact_name || '',
    business_type: user.business?.business_type || '',
    business_location: user.business?.business_location || '',
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: '', text: '' });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSaveChanges = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage({ type: '', text: '' });

    const updatedUser = {
      userId: user.id,
      location: formData.location,
      business_name: formData.business_name || null,
      business_contact_name: formData.business_contact_name || null,
      business_type: formData.business_type || null,
      business_location: formData.business_location || null,
    };

    try {
        const data = await updateProfile(updatedUser);
        if (data.success) {
          // Update user in AuthContext
          const updatedProfile = {
            ...user,
            ...data.updatedUser,
            business: {
              ...user.business,
              business_name: updatedUser.business_name,
              business_contact_name: updatedUser.business_contact_name,
              business_type: updatedUser.business_type,
              business_location: updatedUser.business_location,
            },
          };
          updateUser(updatedProfile);
        // Update localStorage
        localStorage.setItem('user', JSON.stringify(updatedProfile));
        setMessage({ type: 'success', text: 'Profile updated successfully!' });
        // Optionally, return to profile view
        setTimeout(() => {
          setLoading(false);
          onCancel();
        }, 1500);
      } else {
        setMessage({ type: 'error', text: 'Error updating profile: ' + data.message });
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      setMessage({ type: 'error', text: 'An error occurred while updating the profile.' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <section id="edit-profile-form" className="pt-4 pb-4 bg_grey">
       
      <div className="container-xl">
        <div className="row contact_22 mt-4">
          <div className="col-md-4"></div>
          <div className="col-md-8">
            <div className="contact_2l1 mt-3 row">
              <div className="col-md-6">
                <div align="center" className="profile-container">
                  <img  src={defaultProfile} alt="Profile Icon" className="profile-iconn" />
                  <h4 align="center" className='text-white'>{user.name}</h4>
                </div>
              </div>
            </div>

            <form onSubmit={handleSaveChanges}>
              <div className="contact_2l1 mt-3 row">
                <div className="col-md-6">
                  <div className="contact_2l1i">
                    <label>Email</label>
                    <input id="edit-email" className="form-control" value={user.email} disabled />
                  </div>
                </div>
              </div>

              <div className="contact_2l1 mt-3 row">
                <div className="col-md-6">
                  <div className="contact_2l1i">
                    <label>Location</label>
                    <input
                      id="edit-location"
                      name="location"
                      className="form-control"
                      value={formData.location}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>

              <div className="contact_2l1 mt-3 row">
                <div className="col-md-6">
                  <div className="contact_2l1i">
                    <label>User Type</label>
                    <input id="edit-user_type" className="form-control" value={user.user_type} disabled />
                  </div>
                </div>
              </div>

              {/* Business Information */}
              {user.user_type === 'event_promoter' && (
                <>
                  <div className="contact_2l1 mt-3 row">
                    <div className="col-md-6">
                      <div className="contact_2l1i">
                        <label>Business Name</label>
                        <input
                          id="edit-business_name"
                          name="business_name"
                          className="form-control"
                          value={formData.business_name}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="contact_2l1 mt-3 row">
                    <div className="col-md-6">
                      <div className="contact_2l1i">
                        <label>Business Contact Name</label>
                        <input
                          id="edit-business_contact_name"
                          name="business_contact_name"
                          className="form-control"
                          value={formData.business_contact_name}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="contact_2l1 mt-3 row">
                    <div className="col-md-6">
                      <div className="contact_2l1i">
                        <label>Business Type</label>
                        <input
                          id="edit-business_type"
                          name="business_type"
                          className="form-control"
                          value={formData.business_type}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="contact_2l1 mt-3 row">
                    <div className="col-md-6">
                      <div className="contact_2l1i">
                        <label>Business Location</label>
                        <input
                          id="edit-business_location"
                          name="business_location"
                          className="form-control"
                          value={formData.business_location}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}

              <br />
              <div className="contact_2l1 mt-3 row">
                <div className="col-md-6">
                  <div className="contact_2l1i text-center">
                    <button type="submit" className="btn btn-success" disabled={loading}>
                      {loading ? 'Saving...' : 'Save Changes'}
                    </button>
                    <button type="button" className="btn btn-danger" onClick={onCancel} style={{ marginLeft: '10px' }}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </form>

            {/* Messages */}
            {loading && <LoadingModal message="Updating profile..." />}
            {message.type === 'success' && (
              <div className="alert alert-success text-center">{message.text}</div>
            )}
            {message.type === 'error' && (
              <div className="alert alert-danger text-center">{message.text}</div>
            )}
          </div>
        </div>
        <br /><br /><br />
      </div>
    </section>
  );
};

export default EditProfileForm;
