// src/pages/CreateEventPage.js
import React from 'react';
import CreateEventForm from '../components/forms/CreateEventForm';

const CreateEventPage = () => {
  return (
    <div>
      <CreateEventForm />
    </div>
  );
};

export default CreateEventPage;
