// src/components/RetrieveMomentsModal.js
import React, { useState, useEffect } from 'react';
import { FootageService } from '../../services/FootageService';

const RetrieveMomentsModal = ({ eventId }) => {
  const [statusMessage, setStatusMessage] = useState('Checking the status of your event footage...');
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const checkStatus = async () => {
      const data = await FootageService.checkFootageStatus(eventId);
      if (data.success && data.ready) {
        setStatusMessage('Congrats! Now you can download your event footage.');
        setIsReady(true);
      } else {
        setStatusMessage(
          'Footage is not yet ready. We will update you once it\'s ready for download.'
        );
      }
    };
    checkStatus();
  }, [eventId]);

  const handleDownload = () => {
    window.location.href = `/moments`;
  };

  return (
    <div
      className="modal fade"
      id="retrieveMomentsModal"
      tabIndex="-1"
      aria-labelledby="retrieveMomentsLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content custom-modal">
          <div className="modal-header">
            <h5 className="modal-title text-white" id="retrieveMomentsLabel">
              Retrieve Event Footage
            </h5>
            <button
              type="button"
              className="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div align='center' className="modal-body">
            <p>{statusMessage}</p>
            {isReady && (
              <button align='center' className="button" onClick={handleDownload}>
                Download Footage
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RetrieveMomentsModal;
