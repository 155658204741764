// src/components/ActionButtons.js
import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AuthContext } from '../context/AuthContext';
import { CheckInService } from '../services/CheckInService';
import UploadFootageModal from './modals/UploadFootageModal';
import EditEventModal from './modals/EditEventModal';
import RetrieveMomentsModal from './modals/RetrieveMomentsModal';

const ActionButtons = ({ event, onCheckIn }) => {
  const { user } = useContext(AuthContext);
  const [checkedIn, setCheckedIn] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showRetrieveMomentsModal, setShowRetrieveMomentsModal] = useState(false);

  useEffect(() => {
    const checkStatus = async () => {
      if (user && user.user_type === 'event_attendee') {
        const data = await CheckInService.getCheckInStatus(user.id, event.id);
        setCheckedIn(data.checked_in);
      }
    };
    checkStatus();
  }, [user, event.id]);

  if (!user) return null;

  // Check if the user is the event owner and an event promoter
  const isEventOwner = user.id === event.user_id;
  const isEventPromoter = user.user_type === 'event_promoter';

  if (isEventPromoter && isEventOwner) {
    return (
      <div className="action-buttons" align="right" style={{ marginTop: 10 }}>
        <button
          className="button"
          onClick={() => setShowUploadModal(true)}
        >
          Upload Footage
        </button>
        <button
          className="button"
          onClick={() => setShowEditModal(true)}
          style={{ marginLeft: 10 }}
        >
          Edit Event
        </button>

        {/* Upload Footage Modal */}
        <UploadFootageModal
          eventId={String(event.id)}
          show={showUploadModal}
          handleClose={() => setShowUploadModal(false)}
        />

        {/* Edit Event Modal */}
        <EditEventModal
          event={event}
          show={showEditModal}
          handleClose={() => setShowEditModal(false)}
        />
      </div>
    );
  }

  // For Event Attendees
  return (
    <div className="action-buttons" align="right" style={{ marginTop: 10 }}>
      {!checkedIn ? (
        <button
          className="button"
          onClick={onCheckIn} // Use the handler to show the modal
        >
          Check In
        </button>
      ) : (
        <>
          <button
            className="button"
            onClick={() => setShowRetrieveMomentsModal(true)}
          >
            Retrieve Moments
          </button>

          {/* Retrieve Moments Modal */}
          <RetrieveMomentsModal
            eventId={String(event.id)}
            show={showRetrieveMomentsModal}
            handleClose={() => setShowRetrieveMomentsModal(false)}
          />
        </>
      )}
    </div>
  );
};

ActionButtons.propTypes = {
  event: PropTypes.object.isRequired,
  onCheckIn: PropTypes.func.isRequired,
};

export default ActionButtons;
