// src/components/ContactForm.js
import React, { useState } from 'react';
import API from '../../config'; // Import the configuration

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [loading, setLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState({ type: '', text: '' });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic validation
    if (!formData.name || !formData.email || !formData.message) {
      alert('All fields are required.');
      return;
    }

    setLoading(true);
    setModalMessage({ type: '', text: '' });

    try {
      const response = await fetch(`${API.CONTACT}/send-contact-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Include authentication headers if necessary
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (data.success) {
        setModalMessage({ type: 'success', text: 'Message sent successfully!' });
        // Reset form
        setFormData({ name: '', email: '', message: '' });
      } else {
        setModalMessage({ type: 'error', text: data.message || 'Failed to send message. Please try again.' });
      }
    } catch (error) {
      console.error('Error:', error);
      setModalMessage({ type: 'error', text: 'An error occurred while sending the message.' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <form id="contactForm" onSubmit={handleSubmit}>
      <div className="contact_2l1 mt-3 row">
        <div className="col-md-6">
          <div className="contact_2l1i">
            <input
              className="form-control"
              name="name"
              id="name"
              placeholder="Name*"
              type="text"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="contact_2l1i">
            <input
              className="form-control"
              name="email"
              id="email"
              placeholder="Email*"
              type="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
        </div>
      </div>
      <div className="contact_2l1 mt-3 row">
        <div className="col-md-12">
          <div className="contact_2l1i">
            <textarea
              id="message"
              name="message"
              placeholder="Comment"
              className="form-control form_text"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
            <h6 className="mt-3 mb-0">
              <button className="button" id="submitButton" type="submit" disabled={loading}>
                {loading ? 'Submitting...' : 'Submit'}
              </button>
            </h6>
          </div>
        </div>
      </div>
      {/* Display modal messages */}
      {modalMessage.type === 'success' && (
        <div className="alert alert-success mt-3 text-center">{modalMessage.text}</div>
      )}
      {modalMessage.type === 'error' && (
        <div className="alert alert-danger mt-3 text-center">{modalMessage.text}</div>
      )}
    </form>
  );
};

export default ContactForm;
