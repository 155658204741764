// src/components/ContactInfo.js
import React from 'react';

const ContactInfo = () => {
  return (
    <div className="row contact_1 bg_dark pt-5 pb-5">
       
      {/* Company Address */}
      <div className="col-md-3">
        <div className="contact_1i row">
          <div className="col-md-2 col-2">
            <div className="contact_1il">
              <span className="col_red fs-3">
                <i className="fa fa-map-marker"></i>
              </span>
            </div>
          </div>
          <div className="col-md-10 col-10">
            <div className="contact_1ir">
              <h5 className="col_red">Company Address</h5>
              <p className="mb-0">5311 Ceaver Sidge Td. Pakland, DE 13507</p>
            </div>
          </div>
        </div>
      </div>
      {/* Office Hours */}
      <div className="col-md-3">
        <div className="contact_1i row">
          <div className="col-md-2 col-2">
            <div className="contact_1il">
              <span className="col_red fs-3">
                <i className="fa fa-clock-o"></i>
              </span>
            </div>
          </div>
          <div className="col-md-10 col-10">
            <div className="contact_1ir">
              <h5 className="col_red">Office Hours</h5>
              <p className="mb-0">Monday To Saturday - 10.00 - 07.00</p>
              <p className="mb-0">Sunday - Closed</p>
            </div>
          </div>
        </div>
      </div>
      {/* E-mail */}
      <div className="col-md-3">
        <div className="contact_1i row">
          <div className="col-md-2 col-2">
            <div className="contact_1il">
              <span className="col_red fs-3">
                <i className="fa fa-envelope"></i>
              </span>
            </div>
          </div>
          <div className="col-md-10 col-10">
            <div className="contact_1ir">
              <h5 className="col_red">E-mail</h5>
              <p className="mb-0">info@gmail.com</p>
              <p className="mb-0">info@gmail.com</p>
            </div>
          </div>
        </div>
      </div>
      {/* Phone Numbers */}
      <div className="col-md-3">
        <div className="contact_1i row">
          <div className="col-md-2 col-2">
            <div className="contact_1il">
              <span className="col_red fs-3">
                <i className="fa fa-phone"></i>
              </span>
            </div>
          </div>
          <div className="col-md-10 col-10">
            <div className="contact_1ir">
              <h5 className="col_red">Phone Numbers</h5>
              <p className="mb-0">+123 123 456</p>
              <p className="mb-0">+123 123 456</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
