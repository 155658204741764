import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CheckInService } from '../../services/CheckInService';
import LoadingModal from './LoadingModal';

const SelfiePickerModal = ({ show, onClose, eventId, user }) => {
  const [loading, setLoading] = useState(false);
  const [visibility, setVisibility] = useState('private');
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const modalRef = useRef(null);
  const [stream, setStream] = useState(null);

  useEffect(() => {
    if (!modalRef.current) return;

    const modalElement = modalRef.current;
    if (show) {
      modalElement.classList.add('show');
      modalElement.style.display = 'block';
      modalElement.setAttribute('aria-modal', 'true');
      modalElement.removeAttribute('aria-hidden');
      startCamera();
    } else {
      modalElement.classList.remove('show');
      modalElement.style.display = 'none';
      modalElement.setAttribute('aria-hidden', 'true');
      modalElement.removeAttribute('aria-modal');
      resetState();
    }

    const handleHidden = () => {
      if (onClose) {
        onClose();
      }
      resetState();
    };

    modalElement.addEventListener('hidden.bs.modal', handleHidden);

    return () => {
      if (modalElement) {
        modalElement.removeEventListener('hidden.bs.modal', handleHidden);
      }
      stopCamera();
    };
  }, [show, onClose]);

  const resetState = () => {
    setVisibility('private');
    setLoading(false);
    if (canvasRef.current) {
      canvasRef.current.style.display = 'none';
    }
    if (videoRef.current) {
      videoRef.current.style.display = 'block';
    }
  };

  const startCamera = async () => {
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({ video: true });
      setStream(mediaStream);
      if (videoRef.current) {
        videoRef.current.srcObject = mediaStream;
      }
    } catch (err) {
      console.error('Error accessing camera:', err);
      alert('Unable to access your camera. Please check your permissions.');
      if (modalRef.current) {
        modalRef.current.classList.remove('show');
        modalRef.current.style.display = 'none';
      }
    }
  };

  const stopCamera = () => {
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
      setStream(null);
    }
  };

  const captureSelfie = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;

    if (video && canvas) {
      const maxWidth = 400; // Maximum width for the canvas
      const aspectRatio = video.videoWidth / video.videoHeight;

      // Set canvas width and height to maintain aspect ratio within the modal
      canvas.width = Math.min(maxWidth, video.videoWidth); // Limit the width
      canvas.height = canvas.width / aspectRatio;

      const context = canvas.getContext('2d');
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      
      // Hide video and display canvas
      video.style.display = 'none';
      canvas.style.display = 'block';
    }
  };

  const handleCheckIn = async (e) => {
    e.preventDefault();
    setLoading(true);

    const canvas = canvasRef.current;
    if (!canvas) {
      alert('Canvas element not found.');
      setLoading(false);
      return;
    }

    canvas.toBlob(async (blob) => {
      if (!blob) {
        alert('Failed to capture selfie.');
        setLoading(false);
        return;
      }

      const formData = new FormData();
      formData.append('event_id', eventId);
      formData.append('user_id', String(user.id));
      formData.append('visibility', visibility);
      formData.append('selfie', blob, 'selfie.png');

      try {
        const result = await CheckInService.checkIn(formData);
        setLoading(false);

        if (result.success) {
          alert('Check-in successful!');
          if (modalRef.current) {
            modalRef.current.classList.remove('show');
            modalRef.current.style.display = 'none';
          }
          window.location.reload();
        } else {
          alert('Error during check-in: ' + result.message);
        }
      } catch (error) {
        console.error('Error during check-in:', error);
        alert('An error occurred during check-in.');
        setLoading(false);
      }
    }, 'image/png');
  };

  return (
    <>
      {loading && <LoadingModal message="Submitting check-in..." />}
      <div
        className="modal fade"
        id="selfiePickerModal"
        tabIndex="-1"
        aria-labelledby="selfiePickerLabel"
        aria-hidden="true"
        ref={modalRef}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content custom-modal bg-transparent border-0 shadow-none">
            <div className="modal-header">
              <h5 className="modal-title text-white" id="selfiePickerLabel">
                Take Selfie for Check-in
              </h5>
              <button
                type="button"
                className="btn-close btn-close-white"
                onClick={onClose}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleCheckIn}>
                {/* Camera Preview and Capture Area */}
                <div className="mb-3">
                  <label className="form-label text-white">Take Selfie</label>
                  <video
                    ref={videoRef}
                    autoPlay
                    playsInline
                    style={{ width: '100%', height: 'auto', borderRadius: '10px' }}
                  ></video>
                  <canvas ref={canvasRef} style={{ display: 'none', maxWidth: '100%' }}></canvas><br/>
                  <button
                    type="button"
                    className="button"
                    onClick={captureSelfie}
                  >
                    Capture Selfie
                  </button>
                </div>

                {/* Visibility Options */}
                <div className="mb-3 mt-4">
                  <h6 className="text-white">Visibility</h6>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="visibility"
                      id="private-visibility"
                      value="private"
                      checked={visibility === 'private'}
                      onChange={(e) => setVisibility(e.target.value)}
                    />
                    <label className="form-check-label text-white" htmlFor="private-visibility">
                      Private (Only you can see your check-in)
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="visibility"
                      id="public-visibility"
                      value="public"
                      checked={visibility === 'public'}
                      onChange={(e) => setVisibility(e.target.value)}
                    />
                    <label className="form-check-label text-white" htmlFor="public-visibility">
                      Public (Everyone can see your check-in)
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="visibility"
                      id="restricted-visibility"
                      value="restricted"
                      checked={visibility === 'restricted'}
                      onChange={(e) => setVisibility(e.target.value)}
                    />
                    <label className="form-check-label text-white" htmlFor="restricted-visibility">
                      Restricted (Only friends can see your check-in)
                    </label>
                  </div>
                </div>

                {/* Submit Button */}
                <div className="d-grid gap-2 mt-3">
                  <button type="submit" className="button">
                    Check In
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// Define PropTypes for type checking
SelfiePickerModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  eventId: PropTypes.string.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default SelfiePickerModal;
