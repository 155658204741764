// src/components/ProfileDetails.js
import React, { useState } from 'react';
import EditProfileForm from './forms/EditProfileForm';
import defaultProfile from '../assets/img/default-profile.jpg';
import '../assets/css/Profile.css'
const ProfileDetails = ({ user }) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleEditProfile = () => {
    setIsEditing(true);
  };

  if (isEditing) {
    return <EditProfileForm user={user} onCancel={() => setIsEditing(false)} />;
  }

  return (
    <section id="profile-details" className="pt-4 pb-4 bg_grey">
        <br /><br />
      <div className="container-xl">
        <div className="row contact_22 mt-4">
          <div className="col-md-4"></div>
          <div className="col-md-8">
            <div className="contact_2l1 mt-3 row">
              <div className="col-md-6">
                <div className="profile-container" align="center">
                  <img src={defaultProfile} alt="Profile Icon" className="profile-iconn" />
                  <h4 className='text-white' align="center" id="user-name">{user.name}</h4>
                </div>
              </div>
            </div>

            <div className="contact_2l1 mt-3 row">
              <div className="col-md-6">
                <div className="contact_2l1i">
                  <label className='text-white'>Email</label>
                  <input id="user-email" className="form-control" value={user.email} disabled />
                </div>
              </div>
            </div>

            <div className="contact_2l1 mt-3 row">
              <div className="col-md-6">
                <div className="contact_2l1i">
                  <label className='text-white'>Location</label>
                  <input id="user-location" className="form-control" value={user.location || ''} disabled />
                </div>
              </div>
            </div>

            <div className="contact_2l1 mt-3 row">
              <div className="col-md-6">
                <div className="contact_2l1i">
                  <label className='text-white'>User Type</label>
                  <input id="user-type" className="form-control" value={user.user_type} disabled />
                </div>
              </div>
            </div>

            {/* Business Information */}
            {user.user_type === 'event_promoter' && user.business && (
              <>
                <div className="contact_2l1 mt-3 row">
                  <div className="col-md-6">
                    <div className="contact_2l1i">
                      <label className='text-white'>Business Name</label>
                      <input id="user-business_name" className="form-control" value={user.business.business_name || ''} disabled />
                    </div>
                  </div>
                </div>
                <div className="contact_2l1 mt-3 row">
                  <div className="col-md-6">
                    <div className="contact_2l1i">
                      <label className='text-white'>Business Contact Name</label>
                      <input id="user-business_contact_name" className="form-control" value={user.business.business_contact_name || ''} disabled />
                    </div>
                  </div>
                </div>
                <div className="contact_2l1 mt-3 row">
                  <div className="col-md-6">
                    <div className="contact_2l1i">
                      <label className='text-white'>Business Type</label>
                      <input id="user-business_type" className="form-control" value={user.business.business_type || ''} disabled />
                    </div>
                  </div>
                </div>
                <div className="contact_2l1 mt-3 row">
                  <div className="col-md-6">
                    <div className="contact_2l1i">
                      <label className='text-white'>Business Location</label>
                      <input id="user-business_location" className="form-control" value={user.business.business_location || ''} disabled />
                    </div>
                  </div>
                </div>
              </>
            )}

            <br />
            <div className="contact_2l1 mt-3 row">
              <div className="col-md-6">
                <div className="contact_2l1i text-center">
                  <button className="button" onClick={handleEditProfile}>
                    Edit Profile
                  </button>
                </div>
              </div>
            </div>

          </div>
        </div>
        <br />
      </div>
    </section>
  );
};

export default ProfileDetails;
