// src/components/modals/LoadingModal.js
import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Spinner, Alert, Button } from 'react-bootstrap';

const LoadingModal = ({ show, message, isSuccess = false, isError = false, onClose = () => {} }) => {
  return (
    <Modal show={show} onHide={onClose} backdrop="static" keyboard={false} centered>
      <Modal.Body className="d-flex flex-column align-items-center justify-content-center">
        {/* Display Success Message */}
        {isSuccess && (
          <Alert variant="success" className="w-100 text-center">
            {message}
          </Alert>
        )}

        {/* Display Error Message */}
        {isError && (
          <Alert variant="danger" className="w-100 text-center">
            {message}
          </Alert>
        )}

        {/* Display Loading Spinner and Message */}
        {!isSuccess && !isError && (
          <>
            <Spinner animation="border" role="status" className="mb-3">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <p className="text-center">{message}</p>
          </>
        )}
      </Modal.Body>

      {/* Display Close Button for Success and Error Messages */}
      {(isSuccess || isError) && (
        <Modal.Footer className="justify-content-center">
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

// Define PropTypes for type checking
LoadingModal.propTypes = {
  show: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  isSuccess: PropTypes.bool,
  isError: PropTypes.bool,
  onClose: PropTypes.func, // Optional: Only required if isSuccess or isError
};

// **Remove** defaultProps since we're using default parameters
// LoadingModal.defaultProps = {
//   isSuccess: false,
//   isError: false,
//   onClose: () => {}, // No-op function if onClose is not provided
// };

export default LoadingModal;
