// src/pages/AboutPage.js
import React from 'react';
import '../assets/css/global.css';
import { Link } from 'react-router-dom';

const AboutPage = () => {
  return (
    <div>
      {/* About Section */}
      <section id="about_pg" className="py-5 bg_grey">
        <br/><br/> <br/><br/>
        <div className="container-xl">
          <div className="row about_pg1">
            <div className="col-md-12 text-center">
              <h2 className="mb-4">
                <span className="col_red">We Are S P O N O</span>
                <br />
                <small className="slogan">Enjoy the moment while we capture it for you</small>
              </h2>
              <div className="container-xl">
                <br/><br/>
                <div className="row">
                  {/* Vision */}
                  <div className="col-md-6 mb-4">
                    <div className="card bg_dark border-0 shadow-none">
                      <div className="card-body">
                        <h3 className="col_red">Our Vision</h3>
                        <p className="col_light">
                        At Spono our vision is to transform the way people engage, by allowing them to fully immerse in the moment.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* Mission */}
                  <div className="col-md-6 mb-4">
                    <div className="card bg_dark border-0 shadow-none">
                      <div className="card-body">
                        <h3 className="col_red">Our Mission</h3>
                        <p className="col_light">
                         Our Mission is to provide everyone attending an event with a personalized experience, no matter where in the world the event takes place.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br/><br/>
      </section>


      {/* Additional Information Section */}
      <section id="additional_info" className="py-5 bg_grey">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-12">
              <h4 className="text-center mb-4 col_red">Why Choose S P O N O?</h4>
              <p className="w-75 mx-auto text-center" >
                S P O N O leverages cutting-edge technology and creative expertise to deliver unforgettable experiences. Whether you're hosting a local event or a global gathering, our dedicated team ensures every moment is beautifully captured and shared.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutPage;
