// src/services/EventService.js
import axiosInstance from './axiosInstance';
export const fetchEventById = async (eventId) => {
  try {
    const response = await axiosInstance.get(`/${eventId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching event by ID:', error);
    return { success: false, message: 'Error fetching event details' };
  }
};

export const updateEvent = async (eventId, formData, onUploadProgress) => {
  try {
    const response = await axiosInstance.put(`/${eventId}/update`, formData, {
      onUploadProgress,
    });
    return response.data;
  } catch (error) {
    console.error('Error updating event:', error);
    const errorMessage = error.response?.data?.message || 'Error updating event';
    return { success: false, message: errorMessage };
  }
};

export const createEvent = async (eventData, onUploadProgress) => {
  try {
    const response = await axiosInstance.post(`/create-event`, eventData, {
      onUploadProgress,
    });
    return response.data;
  } catch (error) {
    console.error('Error creating event:', error);
    const errorMessage = error.response?.data?.message || 'Error creating event';
    return { success: false, message: errorMessage };
  }
};
