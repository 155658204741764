// src/components/CreateEventForm.js
import React, { useState, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { createEvent } from '../../services/EventService';
import LoadingModal from '../modals/LoadingModal';
import { ProgressBar } from 'react-bootstrap'; // Ensure react-bootstrap is installed

const CreateEventForm = () => {
  const { user } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    event_title: '',
    event_date: '',
    event_time: '',
    location: '',
    event_summary: '',
    event_description: '',
    event_category: 'Conference',
    status: 'active',
  });
  const [eventGraphic, setEventGraphic] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: '', text: '' });
  const [uploadProgress, setUploadProgress] = useState(0); // State for upload progress

  if (!user) {
    return <div>Please log in to create an event.</div>;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleGraphicChange = (e) => {
    setEventGraphic(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage({ type: '', text: '' });
    setUploadProgress(0); // Reset upload progress before starting

    const eventData = new FormData();
    // **Remove** the 'user_id' as the backend extracts it from the token
    // eventData.append('user_id', user.id); // Remove this line

    eventData.append('event_title', formData.event_title);
    eventData.append('event_description', formData.event_description);
    if (eventGraphic) {
      eventData.append('event_graphic', eventGraphic);
    }
    eventData.append('event_date', formData.event_date);
    eventData.append('event_time', formData.event_time);
    eventData.append('location', formData.location);
    eventData.append('event_summary', formData.event_summary);
    eventData.append('event_category', formData.event_category);
    eventData.append('status', formData.status);

    try {
      const result = await createEvent(eventData, (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setUploadProgress(percentCompleted);
      });

      if (result.success) {
        setMessage({ type: 'success', text: 'Event created successfully!' });
        setFormData({
          event_title: '',
          event_date: '',
          event_time: '',
          location: '',
          event_summary: '',
          event_description: '',
          event_category: 'Conference',
          status: 'active',
        });
        setEventGraphic(null);
        setUploadProgress(0); // Reset upload progress after completion

        // Clear the success message after 3 seconds
        setTimeout(() => {
          setMessage({ type: '', text: '' });
        }, 3000);
      } else {
        setMessage({ type: 'danger', text: 'Error creating event: ' + result.message });
      }
    } catch (error) {
      console.error('Error:', error);
      const errorMessage = error.response?.data?.message || 'An error occurred while creating the event.';
      setMessage({ type: 'danger', text: errorMessage });
    } finally {
      setLoading(false);
    }
  };

  return (
    <section id="create-event-form" className="pt-4 pb-4 bg_grey">
      <br /><br /><br /><br />
      <div className="container-fluid">
        <div className="row contact_22 mt-4">
          <div className="col-md-4"></div>
          <div className="col-md-8">
            <div className="contact_2l row">
              <div className="col-md-6">
                <h4 align="center" className='text-white'>Create Event</h4>
              </div>
            </div>

            <form onSubmit={handleSubmit}>
              {/* Event Title */}
              <div className="contact_2l1 mt-3 row">
                <div className="col-md-6">
                  <div className="contact_2l1i">
                    <label className='text-white'>Event Title</label>
                    <input
                      className="form-control custom-input"
                      type="text"
                      name="event_title"
                      value={formData.event_title}
                      onChange={handleChange}
                      placeholder="Event Title"
                      required
                    />
                  </div>
                </div>
              </div>

              {/* Event Date */}
              <div className="contact_2l1 mt-3 row">
                <div className="col-md-6">
                  <div className="contact_2l1i">
                    <label className='text-white'>Date</label>
                    <input
                      className="form-control custom-input"
                      type="date"
                      name="event_date"
                      value={formData.event_date}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              </div>

              {/* Event Time */}
              <div className="contact_2l1 mt-3 row">
                <div className="col-md-6">
                  <div className="contact_2l1i">
                    <label className='text-white'>Time</label>
                    <input
                      className="form-control custom-input"
                      type="time"
                      name="event_time"
                      value={formData.event_time}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              </div>

              {/* Location */}
              <div className="contact_2l1 mt-3 row">
                <div className="col-md-6">
                  <div className="contact_2l1i">
                    <label className='text-white'>Location</label>
                    <input
                      className="form-control custom-input"
                      type="text"
                      name="location"
                      value={formData.location}
                      onChange={handleChange}
                      placeholder="Location"
                      required
                    />
                  </div>
                </div>
              </div>

              {/* Event Summary */}
              <div className="contact_2l1 mt-3 row">
                <div className="col-md-6">
                  <div className="contact_2l1i">
                    <label className='text-white'>Event Summary</label>
                    <textarea
                      className="form-control custom-textarea"
                      name="event_summary"
                      value={formData.event_summary}
                      onChange={handleChange}
                      placeholder="Event Summary"
                      required
                    ></textarea>
                  </div>
                </div>
              </div>

              {/* Event Description */}
              <div className="contact_2l1 mt-3 row">
                <div className="col-md-6">
                  <div className="contact_2l1i">
                    <label className='text-white'>Event Description</label>
                    <textarea
                      className="form-control custom-textarea"
                      name="event_description"
                      value={formData.event_description}
                      onChange={handleChange}
                      placeholder="Event Description"
                      required
                    ></textarea>
                  </div>
                </div>
              </div>

              {/* Event Category */}
              <div className="contact_2l1 mt-3 row">
                <div className="col-md-6">
                  <div className="contact_2l1i">
                    <label className='text-white'>Event Category</label>
                    <select
                      className="form-control custom-select"
                      name="event_category"
                      value={formData.event_category}
                      onChange={handleChange}
                      required
                    >
                      <option value="Conference">Conference</option>
                      <option value="Music">Music</option>
                      <option value="Art">Art</option>
                      <option value="Workshop">Workshop</option>
                      <option value="Networking">Networking</option>
                    </select>
                  </div>
                </div>
              </div>

              {/* Event Graphic */}
              <div className="contact_2l1 mt-3 row">
                <div className="col-md-6">
                  <div className="contact_2l1i">
                    <label className='text-white'>Upload Event Graphic</label>
                    <input
                      className="form-control custom-file-input"
                      type="file"
                      accept="image/*"
                      onChange={handleGraphicChange}
                    />
                  </div>
                </div>
              </div>

              {/* Event Status */}
              <div className="contact_2l1 mt-3 row">
                <div className="col-md-6">
                  <div className="contact_2l1i">
                    <label className='text-white'>Event Status</label>
                    <div>
                      <label className='text-white'>
                        <input
                          type="radio"
                          name="status"
                          value="active"
                          checked={formData.status === 'active'}
                          onChange={handleChange}
                          required
                        />{' '}
                        Activate
                      </label>
                      <label style={{ marginLeft: '10px' }} className='text-white'>
                        <input
                          type="radio"
                          name="status"
                          value="inactive"
                          checked={formData.status === 'inactive'}
                          onChange={handleChange}
                        />{' '}
                        Deactivate
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              {/* Submit Button */}
              <div className="contact_2l1 mt-3 row">
                <div className="col-md-6">
                  <div className="contact_2l1i">
                    <h6 align="center" className="mt-3 mb-0">
                      <button type="submit" className="button" disabled={loading}>
                        Create Event
                      </button>
                    </h6>
                    <br />
                  </div>
                </div>
              </div>
            </form>
            <div className='col-md-6'>
              {/* Loading Modal */}
              {loading && <LoadingModal message="Creating event..." />}

              {/* Upload Progress Bar */}
              {uploadProgress > 0 && (
                <div className="mt-3" align="center">
                  <ProgressBar
                    now={uploadProgress}
                    label={`${uploadProgress}%`}
                    animated
                    striped
                    variant="danger" // Match your styling
                  />
                  <p className="mt-2 text-white">{`Uploading: ${uploadProgress}%`}</p>
                </div>
              )}

              {/* Success/Error Messages */}
              {message.type === 'success' && (
                <div className="alert alert-success text-center mt-3">{message.text}</div>
              )}
              {message.type === 'danger' && (
                <div className="alert alert-danger text-center mt-3">{message.text}</div>
              )}
            </div>
            
          </div>
        </div>
        <br />
      </div>
    </section>
  );
};

export default CreateEventForm;
