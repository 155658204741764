// src/components/ForgotPasswordForm.js
import React, { useState } from 'react';
import { forgotPassword } from '../../services/AuthService';
import { useNavigate } from 'react-router-dom';
import AuthLayout from '../AuthLayout'; 

const ForgotPasswordForm = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = await forgotPassword(email);
    setLoading(false);

    if (data.success) {
      setMessage('Verification code sent to your email.');
      setTimeout(() => {
        navigate('/verify-code', { state: { email } });
      }, 1500);
    } else {
      setMessage(data.message || 'Error sending verification code.');
    }
  };

  return (
    <AuthLayout>
      <section id="forgot-password-form" className="pt-4 pb-4 bg_grey">
      <br /><br /><br /><br /> <br /><br />
        <div className="container-xl">
          <div className="row contact_22 mt-4">
            <div className="col-md-4"></div>
            <div className="col-md-8">
              <div className="contact_2l row">
                <div className="col-md-6">
                  <h4 align="center" className='text-white'>Forgot Password</h4>
                </div>
              </div>
              <form onSubmit={handleForgotPassword}>
                <div className="contact_2l1 mt-3 row">
                  <div className="col-md-6">
                    <input
                      className="form-control"
                      placeholder="Enter your email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                </div>
                {message && <div className="mt-2">{message}</div>}
                <div className="contact_2l1 mt-3 row">
                  <div className="col-md-6">
                    <h6 align="center" className="mt-3 mb-0">
                      <button className="button" type="submit" disabled={loading}>
                        {loading ? 'Sending...' : 'Submit'}
                      </button>
                    </h6>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <br /><br /><br /><br /> <br /><br />
      </section>
    </AuthLayout>
  );
};

export default ForgotPasswordForm;
